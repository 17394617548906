<template>
  <div>
    <v-row class="mt-3">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="./" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-playlist-check</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-light">TEAM ANWESENHEIT</h3>
        <h2 class="white--text font-weight-bold">
          {{ veranstaltung.data.mannschaft }}
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="veranstaltung.data">
      <v-col
        cols="4"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          {{ parseDate(veranstaltung.data.beginn.datum) }}
          <br />
          <span class="font-weight-bold">
            {{ veranstaltung.data.beginn.uhrzeit }}
          </span>
        </h4>
      </v-col>
      <v-col
        cols="2"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        BIS
      </v-col>
      <v-col
        cols="4"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          {{ parseDate(veranstaltung.data.ende.datum) }}
          <br />
          <span class="font-weight-bold">
            {{ veranstaltung.data.ende.uhrzeit }}
          </span>
        </h4>
      </v-col>
      <v-col
        cols="10"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum == veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          Am
          <span class="font-weight-bold">
            {{ parseDate(veranstaltung.data.beginn.datum) }}
          </span>
          von
          <span class="font-weight-bold">
            {{ veranstaltung.data.beginn.uhrzeit }}
          </span>
          bis
          <span class="font-weight-bold">
            {{ veranstaltung.data.ende.uhrzeit }} Uhr
          </span>
        </h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-tabs centered fixed-tabs>
            <v-tab>Sportler</v-tab>
            <v-tab-item>
              <v-row justify="center">
                <v-col cols="6" align-self="center">
                  <h3>
                    {{ anwesende_spieler.length }}
                    <span class="font-weight-light">/{{ member.length }}</span>
                  </h3>
                </v-col>
                <v-col cols="6" class="text-right">
                  <span
                    class="font-weight-light"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    SORTIERUNG:
                  </span>
                  <v-btn text @click="change_sort()">
                    <v-icon class="mr-1">mdi-sort-ascending</v-icon>
                    {{ sort == 'vorname' ? 'VORNAME' : 'Nachname' }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="text-center" v-if="loading_member">
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
                <v-col cols="12" class="text-center" v-if="!loading_member">
                  <h3 class="my-4 white--text" v-if="member.length == 0">
                    Du hast für dieses Team noch keine Mitglieder erstellt.
                  </h3>
                  <v-row
                    v-for="(m, index) in member"
                    :key="m.id"
                    class="py-0 my-0"
                  >
                    <v-expand-x-transition>
                      <v-col align-self="center" class="my-0 py-0">
                        <v-card
                          class="my-1"
                          :color="
                            m.anwesend
                              ? 'success'
                              : (gg_regel || ggplus_regel) &&
                                needs_gg(m) &&
                                !geimpft({
                                  geimpft: m.geimpft,
                                  impfdatum: m.impfdatum,
                                }) &&
                                !genesen(m.genesen)
                              ? 'rgba(0,0,0,0.6)'
                              : 'rgba(0,0,0,0.6)'
                          "
                          :loading="loading == m.id"
                          @click="
                            m.anwesend
                              ? abwesend(index)
                              : (gg_regel || ggplus_regel) &&
                                needs_gg(m) &&
                                !geimpft({
                                  geimpft: m.geimpft,
                                  impfdatum: m.impfdatum,
                                }) &&
                                !genesen(m.genesen)
                              ? (update_immunisierung = m)((show_dialog = true))
                              : anwesend(index)
                          "
                        >
                          <v-card-title>
                            <v-icon class="mr-4">
                              {{
                                m.anwesend
                                  ? 'mdi-check-bold'
                                  : (gg_regel || ggplus_regel) &&
                                    needs_gg(m) &&
                                    !geimpft({
                                      geimpft: m.geimpft,
                                      impfdatum: m.impfdatum,
                                    }) &&
                                    !genesen(m.genesen)
                                  ? 'mdi-close'
                                  : 'mdi-check-outline'
                              }}
                            </v-icon>

                            {{ sort == 'vorname' ? m.vorname : m.name }}
                            {{ sort == 'vorname' ? m.name : m.vorname }}
                            <v-icon
                              v-if="(gg_regel || ggplus_regel) && needs_gg(m)"
                              class="ml-2"
                            >
                              {{
                                (gg_regel || ggplus_regel) &&
                                needs_gg(m) &&
                                !geimpft({
                                  geimpft: m.geimpft,
                                  impfdatum: m.impfdatum,
                                }) &&
                                !genesen(m.genesen)
                                  ? m.getestet && m.anwesend
                                    ? 'mdi-eyedropper'
                                    : 'mdi-shield-off-outline'
                                  : 'mdi-shield-check'
                              }}
                            </v-icon>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-expand-x-transition>
                    <v-expand-x-transition>
                      <v-col
                        cols="auto"
                        class="pl-0"
                        align-self="center"
                        v-if="
                          (!geimpft({
                            geimpft: m.geimpft,
                            impfdatum: m.impfdatum,
                          }) &&
                            !genesen(m.genesen) &&
                            m.anwesend &&
                            testpflicht &&
                            needs_gg(m)) ||
                          (m.anwesend &&
                            (geimpft({
                              geimpft: m.geimpft,
                              impfdatum: m.impfdatum,
                            }) ||
                              genesen(m.genesen)) &&
                            ggplus_regel &&
                            needs_gg(m))
                        "
                      >
                        <v-icon
                          v-if="!m.ggg"
                          @click="m.anwesend ? getestet(index) : undefined"
                          large
                        >
                          mdi-eyedropper-off
                        </v-icon>
                        <v-icon
                          v-if="m.ggg"
                          :color="template.colors.success"
                          @click="nicht_getestet(index)"
                          large
                        >
                          mdi-eyedropper
                        </v-icon>
                      </v-col>
                    </v-expand-x-transition>
                  </v-row>
                  <h3
                    class="my-4 white--text"
                    v-if="anwesende_gastspieler.length > 0"
                  >
                    Gast-Sportler:
                  </h3>
                  <v-card
                    v-for="gast in anwesende_gastspieler"
                    :key="gast.id"
                    class="my-1"
                    color="success"
                    @click="del(gast)"
                  >
                    <v-card-title>
                      <v-icon class="mr-4">
                        mdi-check-bold
                      </v-icon>
                      {{ gast.person.vorname }} {{ gast.person.name }}
                      <v-icon class="ml-4">
                        {{
                          gast.ggg
                            ? gast.ggg.genesen || gast.ggg.geimpft
                              ? 'mdi-shield-check'
                              : gast.ggg.getestet
                              ? 'mdi-eyedropper'
                              : ''
                            : ''
                        }}
                      </v-icon>
                    </v-card-title>
                  </v-card>
                  <v-dialog v-model="delguest" persistent max-width="400">
                    <v-card
                      :dark="!template.light"
                      :light="template.light"
                      v-if="del_guest"
                    >
                      <v-card-title class="text-justify">
                        Möchtest Du den Gast-Sportler
                        <br />
                        {{
                          del_guest.person.vorname + ' ' + del_guest.person.name
                        }}
                        wirklich entfernen?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" text @click="delguest = false">
                          Abbrechen
                        </v-btn>
                        <v-btn
                          color="success"
                          text
                          :loading="deleting"
                          @click="deleteGast(del_guest)"
                        >
                          Gast entfernen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12" class="mt-5 text-center pb-0">
                  <cardbutton
                    title="Teammitglied hinzufügen"
                    :textcolor="template.colors.block_text"
                    :bgcolor="template.colors.blocks"
                    upper
                    @click="newmember = true"
                  />
                  <CreateMember
                    v-if="newmember"
                    v-on:closing="newmember = false"
                  />
                </v-col>
                <v-col cols="12" class="pt-0 text-center">
                  <cardbutton
                    title="Gast-Sportler hinzufügen"
                    :textcolor="template.colors.inline_primary_text"
                    :bgcolor="template.colors.primary"
                    upper
                    @click="newtempmember = true"
                  />
                  <CreateGuestMember
                    v-if="newtempmember"
                    v-on:closing="
                      newtempmember = false
                      aktualisiereListe()
                    "
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab>Trainer/Betreuer</v-tab>
            <v-tab-item>
              <v-row justify="center">
                <v-col cols="6" align-self="center">
                  <h3>
                    {{ anwesende_coaches.length }}
                    <span class="font-weight-light">/{{ coaches.length }}</span>
                  </h3>
                </v-col>
                <v-col cols="6" class="text-right">
                  <span
                    class="font-weight-light"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    SORTIERUNG:
                  </span>
                  <v-btn text @click="change_sort()">
                    <v-icon class="mr-1">mdi-sort-ascending</v-icon>
                    {{ sort == 'vorname' ? 'VORNAME' : 'Nachname' }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="text-center" v-if="loading_member">
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
                <v-col cols="12" class="text-center" v-if="!loading_member">
                  <h3 class="my-4 white--text" v-if="coaches.length == 0">
                    Du hast für dieses Team noch keine Trainer erstellt.
                  </h3>
                  <v-row
                    v-for="(m, index) in coaches"
                    :key="m.id"
                    class="py-0 my-0"
                  >
                    <v-expand-x-transition>
                      <v-col align-self="center" class="my-0 py-0">
                        <v-card
                          class="my-1"
                          :color="
                            m.anwesend
                              ? 'success'
                              : (gg_regel || ggplus_regel) &&
                                !geimpft({
                                  geimpft: m.geimpft,
                                  impfdatum: m.impfdatum,
                                }) &&
                                !genesen(m.genesen)
                              ? 'rgba(0,0,0,0.6)'
                              : 'rgba(0,0,0,0.6)'
                          "
                          :loading="loading == m.id"
                          @click="
                            m.anwesend
                              ? abwesendCoach(index)
                              : (gg_regel || ggplus_regel) &&
                                !geimpft({
                                  geimpft: m.geimpft,
                                  impfdatum: m.impfdatum,
                                }) &&
                                !genesen(m.genesen)
                              ? (update_immunisierung = m)((show_dialog = true))
                              : anwesendCoach(index)
                          "
                        >
                          <v-card-title>
                            <v-icon class="mr-4">
                              {{
                                m.anwesend
                                  ? 'mdi-check-bold'
                                  : (gg_regel || ggplus_regel) &&
                                    !geimpft({
                                      geimpft: m.geimpft,
                                      impfdatum: m.impfdatum,
                                    }) &&
                                    !genesen(m.genesen)
                                  ? 'mdi-close'
                                  : 'mdi-check-outline'
                              }}
                            </v-icon>

                            {{ sort == 'vorname' ? m.vorname : m.name }}
                            {{ sort == 'vorname' ? m.name : m.vorname }}
                            <v-icon
                              v-if="(gg_regel || ggplus_regel)"
                              class="ml-2"
                            >
                              {{
                                (gg_regel || ggplus_regel) &&
                                !geimpft({
                                  geimpft: m.geimpft,
                                  impfdatum: m.impfdatum,
                                }) &&
                                !genesen(m.genesen)
                                  ? 'mdi-shield-off-outline'
                                  : 'mdi-shield-check'
                              }}
                            </v-icon>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-expand-x-transition>
                    <v-expand-x-transition>
                      <v-col
                        cols="auto"
                        class="pl-0"
                        align-self="center"
                        v-if="
                          (!geimpft({
                            geimpft: m.geimpft,
                            impfdatum: m.impfdatum,
                          }) &&
                            !genesen(m.genesen) &&
                            m.anwesend &&
                            testpflicht) ||
                          (m.anwesend &&
                            (geimpft({
                              geimpft: m.geimpft,
                              impfdatum: m.impfdatum,
                            }) ||
                              genesen(m.genesen)) &&
                            ggplus_regel)
                        "
                      >
                        <v-icon
                          v-if="!m.ggg"
                          @click="m.anwesend ? getestetCoach(index) : undefined"
                          large
                        >
                          mdi-eyedropper-off
                        </v-icon>
                        <v-icon
                          v-if="m.ggg"
                          :color="template.colors.success"
                          @click="nicht_getestetCoach(index)"
                          large
                        >
                          mdi-eyedropper
                        </v-icon>
                      </v-col>
                    </v-expand-x-transition>
                  </v-row>

                  <h3
                    class="my-4 white--text"
                    v-if="anwesende_gasttrainer.length > 0"
                  >
                    Gast-Trainer/Betreuer:
                  </h3>
                  <v-card
                    v-for="gast in anwesende_gasttrainer"
                    :key="gast.id"
                    class="my-1"
                    color="success"
                    @click="deletecoach(gast)"
                  >
                    <v-card-title>
                      <v-icon class="mr-4">
                        mdi-check-bold
                      </v-icon>
                      {{ gast.person.vorname }} {{ gast.person.name }}
                      {{
                        gast.aktivitaet == 'Betreuer'
                          ? ' (' + gast.aktivitaet + ')'
                          : ''
                      }}
                      <v-icon class="ml-4">
                        {{
                          gast.ggg
                            ? gast.ggg.genesen || gast.ggg.geimpft
                              ? 'mdi-shield-check'
                              : gast.ggg.getestet
                              ? 'mdi-eyedropper'
                              : ''
                            : ''
                        }}
                      </v-icon>
                    </v-card-title>
                  </v-card>
                  <v-dialog v-model="delcoach" persistent max-width="400">
                    <v-card
                      :dark="!template.light"
                      :light="template.light"
                      v-if="del_coach"
                    >
                      <v-card-title class="text-justify">
                        Möchtest Du den Gast-Trainer
                        <br />
                        {{
                          del_coach.person.vorname + ' ' + del_coach.person.name
                        }}
                        wirklich entfernen?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" text @click="delcoach = false">
                          Abbrechen
                        </v-btn>
                        <v-btn
                          color="success"
                          text
                          :loading="deleting"
                          @click="deleteGastCoach(del_coach)"
                        >
                          Gast-Trainer entfernen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12" class="mt-5 text-center pb-0">
                  <cardbutton
                    title="Teammitglied hinzufügen"
                    :textcolor="template.colors.block_text"
                    :bgcolor="template.colors.blocks"
                    upper
                    @click="newmember = true"
                  />
                  <CreateMember
                    v-if="newmember"
                    v-on:closing="newmember = false"
                  />
                </v-col>
                <v-col cols="12" class="pt-0 text-center">
                  <cardbutton
                    title="Gast-Trainer hinzufügen"
                    :textcolor="template.colors.inline_primary_text"
                    :bgcolor="template.colors.primary"
                    upper
                    @click="newtempcoach = true"
                  />
                  <CreateGuestCoach
                    v-if="newtempcoach"
                    v-on:closing="
                      newtempcoach = false
                      aktualisiereListe()
                    "
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <!-- NEXT-V.4.2.1 -->
    <v-dialog
      :content-class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : ''"
      :fullscreen="!$vuetify.breakpoint.mdAndUp"
      v-model="show_dialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? 800 : undefined"
    >
      <v-card class="rouded-xl" v-if="update_immunisierung">
        <v-toolbar :color="template.colors.primary" class="rounded-t-xl">
          <v-toolbar-title>
            {{ update_immunisierung.vorname }} {{ update_immunisierung.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              update_immunisierung = ''
              show_dialog = false
              immunisierung = {
                impfung: {
                  selected: false,
                  check: false,
                },
                genesung: {
                  selected: false,
                  genesen_seit: false,
                  check: false,
                },
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row
          justify="center"
          v-if="
            !immunisierung.impfung.selected &&
            !immunisierung.genesung.selected &&
            update_immunisierung.geimpft &&
            !update_immunisierung.genesen
          "
          style="min-height: 400px;"
        >
          <v-col cols="12" class="text-center" align-self="center">
            <h2>
              Die Person wurde am
              {{ datum(update_immunisierung.impfdatum) }} vollständig geimpft.
              Die Impfung liegt noch nicht mehr als 14 Tage zurück und daher ist
              der vollständige Impfschutz noch nicht erreicht.
            </h2>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          v-if="
            !immunisierung.impfung.selected &&
            !immunisierung.genesung.selected &&
            !update_immunisierung.geimpft &&
            update_immunisierung.genesen
          "
          style="min-height: 400px;"
        >
          <v-col cols="12" class="text-center" align-self="center">
            <h2>
              Die Person ist seit dem
              {{ datum(update_immunisierung.genesen.datum) }} genesen. Die
              Genesung liegt mehr als 6 Monate zurück und daher ist ohne eine
              Impfung eine Immunisierungs nicht mehr vorhanden.
            </h2>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          v-if="
            !immunisierung.impfung.selected &&
            !immunisierung.genesung.selected &&
            !update_immunisierung.geimpft &&
            !update_immunisierung.genesen
          "
          style="min-height: 400px;"
        >
          <v-col cols="12" class="text-center" align-self="center">
            <h2>Immunisierungsstatus ändern</h2>
          </v-col>
          <v-col cols="12" lg="auto" class="text-center">
            <v-btn
              x-large
              :color="template.colors.primary"
              rounded
              @click="immunisierung.impfung.selected = true"
            >
              Person ist vollständig geimpft
            </v-btn>
            <br />
            <v-btn v-if="false" small class="mt-4" text rounded>
              <v-icon class="mr-2">mdi-information</v-icon>
              Wann gilt man als vollständig geimpft?
            </v-btn>
          </v-col>
          <v-col cols="12" lg="auto" class="text-center">
            <v-btn
              x-large
              :color="template.colors.primary"
              rounded
              @click="immunisierung.genesung.selected = true"
            >
              Person ist genesen
            </v-btn>
            <br />
            <v-btn v-if="false" small class="mt-4" text rounded>
              <v-icon class="mr-2">mdi-information</v-icon>
              Wann gilt man als genesen?
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            class="text-center"
            v-if="spiele.includes(veranstaltung.data.art)"
          >
            <v-divider class="mb-5"></v-divider>
            <v-btn
              x-large
              :color="template.colors.primary"
              rounded
              @click="
                immunisierung.getestet = true
                anwesendPCR(update_immunisierung)
              "
            >
              Person hat einen negativen PCR-Test
            </v-btn>
            <br />
            <small>Der Test darf nicht älter als 48 Stunden sein.</small>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          v-if="immunisierung.impfung.selected"
          style="min-height: 400px;"
        >
          <v-col cols="12" class="text-center" align-self="center">
            <h2>Seit wann ist die Person vollständig geimpft?</h2>
          </v-col>
          <v-col cols="auto">
            <v-date-picker
              v-model="immunisierung.impfung.geimpft_seit"
              :color="template.colors.primary"
              class="rounded-xl"
              locale="de"
            ></v-date-picker>
          </v-col>
          <v-col cols="12">
            <v-row justify="space-around">
              <v-btn
                large
                rounded
                text
                @click="
                  immunisierung.impfung = {
                    selected: false,
                    geimpft_seit: '',
                    help: false,
                  }
                "
              >
                Abbrechen
              </v-btn>
              <v-btn
                large
                rounded
                :color="template.colors.success"
                @click="update_mitglied_impfstatus(update_immunisierung)"
              >
                <v-icon class="mr-2">
                  mdi-check
                </v-icon>
                Bestätigen
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          v-if="immunisierung.genesung.selected"
          style="min-height: 400px;"
        >
          <v-col cols="12" class="text-center" align-self="center">
            <h2>Seit wann ist die Person genesen?</h2>
          </v-col>
          <v-col cols="auto">
            <v-date-picker
              v-model="immunisierung.genesung.genesen_seit"
              :color="template.colors.primary"
              class="rounded-xl"
              locale="de"
            ></v-date-picker>
          </v-col>
          <v-col cols="12">
            <v-row justify="space-around">
              <v-btn
                large
                rounded
                text
                @click="
                  immunisierung.genesung = {
                    selected: false,
                    genesen_seit: '',
                    help: false,
                  }
                "
              >
                Abbrechen
              </v-btn>
              <v-btn
                large
                rounded
                :color="template.colors.success"
                @click="update_mitglied_impfstatus(update_immunisierung)"
              >
                <v-icon class="mr-2">
                  mdi-check
                </v-icon>
                Bestätigen
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!--NEXT-V.4.2.1 -->

    <v-snackbar
      app
      timeout="4000"
      v-model="saved"
      multi-line
      color="success"
      centered
      rounded="rounded-xl"
      top
    >
      <h2>
        <v-icon large class="mr-2">mdi-calendar-sync</v-icon>
        Anwesenheitsliste aktualisiert
      </h2>
      <template v-slot:action="{ attrs }">
        <v-btn
          :dark="!template.light"
          icon
          v-bind="attrs"
          @click="saved = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import cardbutton from '../../../elements/cards/card-button'
import CreateMember from '../team/createMember'
import CreateGuestMember from './createGuestMember'
import CreateGuestCoach from './createGuestCoach'
import router from '../../../routes'

export default {
  name: 'Team-Anwesenheit-bearbeiten',
  data() {
    return {
      member: [],
      coaches: [],
      anwesende_spieler: [],
      ggg_spieler: [],
      anwesende_coaches: [],
      ggg_coaches: [],
      anwesende_gastspieler: [],
      anwesende_gasttrainer: [],
      newmember: false,
      newtempmember: false,
      newtempcoach: false,
      delguest: false,
      delcoach: false,
      del_guest: '',
      del_coach: '',
      deleting: false,
      loading: false,
      loading_member: false,
      loaded: false,
      saved: false,
      sort: 'vorname',
      update_immunisierung: '',
      show_dialog: false,
      immunisierung: {
        impfung: {
          selected: false,
          geimpft_seit: '',
          help: false,
        },
        genesung: {
          selected: false,
          genesen_seit: '',
          help: false,
        },
        getestet: false,
      },
      spiele: [
        'Auswärtsspiel',
        'Freundschaftsspiel',
        'Meisterschaftsspiel',
        'Pokalspiel',
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
    testpflicht() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.testpflicht) {
          if (!this.user.data.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      return false
    },
    spieler_auto_ggg() {
      if (this.team.data && this.team.data.ggg_regel) {
        return true
      }
      return false
    },
    gg_regel() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.gg_regel) {
          return true
        }
      }
      return false
    },
    ggplus_regel() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      return false
    },
  },
  components: {
    CreateMember,
    CreateGuestMember,
    CreateGuestCoach,
    cardbutton,
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      if (this.veranstaltung.id != this.$route.params.veranstaltung) {
        this.loading_member = true
        store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      } else {
        this.loading_member = false
        this.loaded = true
      }
      this.load_team()
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  methods: {
    needs_gg(member) {
      if (this.spieler_auto_ggg) {
        return false
      }
      if (member.geburtsdatum) {
        var geburtsdatum = new Zeit(member.geburtsdatum, '00:00:00')
        var mindestalter = new Date()
        mindestalter.setFullYear(mindestalter.getFullYear() - 16)
        if (mindestalter.getTime() < geburtsdatum.getTimestamp()) {
          return false
        }
      }
      return true
    },
    datum(datum) {
      var date = datum.split('-')
      return date[2] + '.' + date[1] + '.' + date[0]
    },
    genesen(daten) {
      if (daten) {
        var date = new Date()
        date.setMonth(date.getMonth() - 6)
        if (date.getTime() <= daten.timestamp) {
          return true
        }
      }
      return false
    },
    geimpft(daten) {
      if (daten && daten.geimpft && daten.impfdatum) {
        var date = new Date()
        date.setDate(date.getDate() - 14)
        var impfdatum = new Zeit(daten.impfdatum, '00:00:00')
        if (date.getTime() >= impfdatum.getTimestamp()) {
          return true
        }
      } else if (daten && daten.geimpft) {
        return true
      }
      return false
    },
    update_mitglied_impfstatus(member) {
      var genesen = new Zeit(
        this.immunisierung.genesung.genesen_seit,
        '00:00:00',
      )
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.veranstaltung.data.mannschaft)
        .collection('Mitglieder')
        .doc(member.id)
        .update({
          geimpft: this.immunisierung.impfung.selected || false,
          impfdatum: this.immunisierung.impfung.geimpft_seit || '',
          genesen: this.immunisierung.genesung.selected
            ? {
                datum: this.immunisierung.genesung.genesen_seit || false,
                timestamp: genesen.getTimestamp() || '',
              }
            : false,
        })
        .then(() => {
          this.update_immunisierung = ''
          this.show_dialog = false
          this.immunisierung = {
            impfung: {
              selected: false,
              check: false,
            },
            genesung: {
              selected: false,
              genesen_seit: false,
              check: false,
            },
          }
        })
    },
    load_team() {
      if (this.veranstaltung.data) {
        this.loaded = true
      }
      setTimeout(() => {
        if (this.loaded) {
          store.dispatch('setTeam', this.veranstaltung.data.mannschaft)
          firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Team')
            .doc(this.veranstaltung.data.mannschaft)
            .collection('Mitglieder')
            .orderBy(this.sort)
            .onSnapshot(
              (querySnap) => {
                this.member = []
                this.coaches = []
                querySnap.forEach((doc) => {
                  if (
                    doc.data().rolle == 'Trainer' ||
                    doc.data().rolle == 'Betreuer'
                  ) {
                    var coach = doc.data()
                    coach.id = doc.id
                    this.coaches.push(coach)
                  } else {
                    var sportler = doc.data()
                    sportler.id = doc.id
                    this.member.push(sportler)
                  }
                })
                this.aktualisiereListe()
                this.loading_member = false
              },
              (error) => {
                router.dispatch('/veranstaltung/' + $route.params.veranstaltung)
              },
            )
        } else {
          this.load_team()
        }
      }, 100)
    },
    change_sort() {
      if (this.sort == 'vorname') {
        this.sort = 'name'
      } else {
        this.sort = 'vorname'
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.veranstaltung.data.mannschaft)
        .collection('Mitglieder')
        .orderBy(this.sort)
        .onSnapshot((querySnap) => {
          this.member = []
          this.coaches = []
          querySnap.forEach((doc) => {
            if (
              doc.data().rolle == 'Trainer' ||
              doc.data().rolle == 'Betreuer'
            ) {
              var coach = doc.data()
              coach.id = doc.id
              this.coaches.push(coach)
            } else {
              var sportler = doc.data()
              sportler.id = doc.id
              this.member.push(sportler)
            }
          })
          this.aktualisiereListe()
        })
    },
    del(gast) {
      this.delguest = true
      this.del_guest = gast
    },
    deletecoach(gast) {
      this.delcoach = true
      this.del_coach = gast
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    anwesend(index) {
      var datum = new Date()
      datum.setDate(datum.getDate() - 180)
      this.loading = this.member[index].id
      var event = this.veranstaltung.data
      event.id = this.veranstaltung.id
      var zutritt = new Zeit(
        this.veranstaltung.data.beginn.datum,
        this.veranstaltung.data.beginn.uhrzeit,
      )
      var verlassen = new Zeit(
        this.veranstaltung.data.ende.datum,
        this.veranstaltung.data.ende.uhrzeit,
      )

      var daten = {
        verein: {
          id: this.user.data.uid,
          name: this.user.data.verein.name,
          spieler: this.member[index].id,
          team: this.veranstaltung.data.mannschaft,
        },
        veranstaltung: event,
        aktivitaet: 'Sportler',
        sportstaette: {
          name: this.veranstaltung.data.sportstaette
            ? this.veranstaltung.data.sportstaette
            : '',
        },
        person: {
          vorname: this.member[index].vorname,
          name: this.member[index].name,
          adresse: this.member[index].adresse,
          plz: this.member[index].plz,
          ort: this.member[index].ort,
          telefon: this.member[index].telefon,
        },
        zutritt: {
          datum: zutritt.getDatum(),
          uhrzeit: zutritt.getUhrzeit(),
          timestamp: zutritt.getTimestamp(),
        },
        verlassen: {
          datum: verlassen.getDatum(),
          uhrzeit: verlassen.getUhrzeit(),
          timestamp: verlassen.getTimestamp(),
        },
        ggg: {
          geimpft: this.member[index].geimpft ? true : false,
          genesen: this.member[index].genesen
            ? this.member[index].genesen.timestamp > datum.getTime()
              ? true
              : false
            : false,
          getestet: this.immunisierung.getestet || false,
        },
      }

      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.spieler', '==', this.member[index].id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          var exists = false
          docRef.forEach((doc) => {
            if (doc.exists) {
              exists = true
            }
          })
          if (!exists) {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Anwesenheit')
              .add(daten)
              .catch((error) => {
                console.log(error)
              })
              .then((doc) => {
                if (this.veranstaltung.data.gastgeberverein) {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.veranstaltung.data.gastgeberverein.id)
                    .collection('Veranstaltung')
                    .doc(this.veranstaltung.data.gastgeberverein.veranstaltung)
                    .get()
                    .then((gastgeberEvent) => {
                      var event = gastgeberEvent.data()
                      daten.veranstaltung = event
                      daten.veranstaltung.id = gastgeberEvent.id
                      daten.sportstaette = {
                        name: event.sportstaette ? event.sportstaette : '',
                      }
                      firebase
                        .firestore()
                        .collection('User')
                        .doc(this.veranstaltung.data.gastgeberverein.id)
                        .collection('Anwesenheit')
                        .where('veranstaltung.id', '==', gastgeberEvent.id)
                        .where('verein.spieler', '==', this.member[index].id)
                        .where(
                          'verein.team',
                          '==',
                          this.veranstaltung.data.mannschaft,
                        )
                        .where('verein.id', '==', this.user.data.uid)
                        .get()
                        .then((docRef) => {
                          var exists = false
                          docRef.forEach((doc) => {
                            if (doc.exists) {
                              exists = true
                            }
                          })
                          if (!exists) {
                            firebase
                              .firestore()
                              .collection('User')
                              .doc(this.veranstaltung.data.gastgeberverein.id)
                              .collection('Anwesenheit')
                              .add(daten)
                              .then(() => {
                                this.aktualisiereListe()
                                this.loading = false
                                this.saved = true
                                this.immunisierung.getestet = false
                              })
                          } else {
                            this.aktualisiereListe()
                            this.loading = false
                            this.saved = true
                            this.immunisierung.getestet = false
                          }
                        })
                    })
                } else {
                  this.aktualisiereListe()
                  this.loading = false
                  this.saved = true
                  this.immunisierung.getestet = false
                }
              })
          } else {
            this.immunisierung.getestet = false
          }
        })
    },
    anwesendPCR(member) {
      var datum = new Date()
      datum.setDate(datum.getDate() - 180)
      this.loading = member.id
      var event = this.veranstaltung.data
      event.id = this.veranstaltung.id
      var zutritt = new Zeit(
        this.veranstaltung.data.beginn.datum,
        this.veranstaltung.data.beginn.uhrzeit,
      )
      var verlassen = new Zeit(
        this.veranstaltung.data.ende.datum,
        this.veranstaltung.data.ende.uhrzeit,
      )

      var daten = {
        verein: {
          id: this.user.data.uid,
          name: this.user.data.verein.name,
          spieler: member.id,
          team: this.veranstaltung.data.mannschaft,
        },
        veranstaltung: event,
        aktivitaet: member.rolle || 'Sportler',
        sportstaette: {
          name: this.veranstaltung.data.sportstaette
            ? this.veranstaltung.data.sportstaette
            : '',
        },
        person: {
          vorname: member.vorname,
          name: member.name,
          adresse: member.adresse,
          plz: member.plz,
          ort: member.ort,
          telefon: member.telefon,
        },
        zutritt: {
          datum: zutritt.getDatum(),
          uhrzeit: zutritt.getUhrzeit(),
          timestamp: zutritt.getTimestamp(),
        },
        verlassen: {
          datum: verlassen.getDatum(),
          uhrzeit: verlassen.getUhrzeit(),
          timestamp: verlassen.getTimestamp(),
        },
        ggg: {
          geimpft: member.geimpft ? true : false,
          genesen: member.genesen
            ? member.genesen.timestamp > datum.getTime()
              ? true
              : false
            : false,
          getestet: this.immunisierung.getestet || false,
        },
      }

      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.spieler', '==', member.id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          var exists = false
          docRef.forEach((doc) => {
            if (doc.exists) {
              exists = true
            }
          })
          if (!exists) {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Anwesenheit')
              .add(daten)
              .catch((error) => {
                console.log(error)
              })
              .then((doc) => {
                if (this.veranstaltung.data.gastgeberverein) {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.veranstaltung.data.gastgeberverein.id)
                    .collection('Veranstaltung')
                    .doc(this.veranstaltung.data.gastgeberverein.veranstaltung)
                    .get()
                    .then((gastgeberEvent) => {
                      var event = gastgeberEvent.data()
                      daten.veranstaltung = event
                      daten.veranstaltung.id = gastgeberEvent.id
                      daten.sportstaette = {
                        name: event.sportstaette ? event.sportstaette : '',
                      }
                      firebase
                        .firestore()
                        .collection('User')
                        .doc(this.veranstaltung.data.gastgeberverein.id)
                        .collection('Anwesenheit')
                        .where('veranstaltung.id', '==', gastgeberEvent.id)
                        .where('verein.spieler', '==', member.id)
                        .where(
                          'verein.team',
                          '==',
                          this.veranstaltung.data.mannschaft,
                        )
                        .where('verein.id', '==', this.user.data.uid)
                        .get()
                        .then((docRef) => {
                          var exists = false
                          docRef.forEach((doc) => {
                            if (doc.exists) {
                              exists = true
                            }
                          })
                          if (!exists) {
                            firebase
                              .firestore()
                              .collection('User')
                              .doc(this.veranstaltung.data.gastgeberverein.id)
                              .collection('Anwesenheit')
                              .add(daten)
                              .then(() => {
                                this.aktualisiereListe()
                                this.loading = false
                                this.saved = true
                                this.immunisierung.getestet = false
                                this.update_immunisierung = ''
                                this.show_dialog = false
                              })
                          } else {
                            this.aktualisiereListe()
                            this.loading = false
                            this.saved = true
                            this.immunisierung.getestet = false
                            this.update_immunisierung = ''
                            this.show_dialog = false
                          }
                        })
                    })
                } else {
                  this.aktualisiereListe()
                  this.loading = false
                  this.saved = true
                  this.immunisierung.getestet = false
                  this.update_immunisierung = ''
                  this.show_dialog = false
                }
              })
          } else {
            this.immunisierung.getestet = false
          }
        })
    },
    anwesendCoach(index) {
      var datum = new Date()
      datum.setDate(datum.getDate() - 180)
      this.loading = this.coaches[index].id
      var event = this.veranstaltung.data
      event.id = this.veranstaltung.id
      var zutritt = new Zeit(
        this.veranstaltung.data.beginn.datum,
        this.veranstaltung.data.beginn.uhrzeit,
      )
      var verlassen = new Zeit(
        this.veranstaltung.data.ende.datum,
        this.veranstaltung.data.ende.uhrzeit,
      )

      var daten = {
        verein: {
          id: this.user.data.uid,
          name: this.user.data.verein.name,
          trainer: this.coaches[index].id,
          team: this.veranstaltung.data.mannschaft,
        },
        veranstaltung: event,
        aktivitaet: this.coaches[index].rolle,
        sportstaette: {
          name: this.veranstaltung.data.sportstaette
            ? this.veranstaltung.data.sportstaette
            : '',
        },
        person: {
          vorname: this.coaches[index].vorname,
          name: this.coaches[index].name,
          adresse: this.coaches[index].adresse,
          plz: this.coaches[index].plz,
          ort: this.coaches[index].ort,
          telefon: this.coaches[index].telefon,
        },
        zutritt: {
          datum: zutritt.getDatum(),
          uhrzeit: zutritt.getUhrzeit(),
          timestamp: zutritt.getTimestamp(),
        },
        verlassen: {
          datum: verlassen.getDatum(),
          uhrzeit: verlassen.getUhrzeit(),
          timestamp: verlassen.getTimestamp(),
        },
        ggg: {
          geimpft: this.coaches[index].geimpft ? true : false,
          genesen: this.coaches[index].genesen
            ? this.coaches[index].genesen.timestamp > datum.getTime()
              ? true
              : false
            : false,
          getestet: this.immunisierung.getestet || false,
        },
      }

      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.trainer', '==', this.coaches[index].id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          var exists = false
          docRef.forEach((doc) => {
            if (doc.exists) {
              exists = true
            }
          })
          if (!exists) {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.data.uid)
              .collection('Anwesenheit')
              .add(daten)
              .catch((error) => {
                console.log(error)
              })
              .then((doc) => {
                if (this.veranstaltung.data.gastgeberverein) {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.veranstaltung.data.gastgeberverein.id)
                    .collection('Veranstaltung')
                    .doc(this.veranstaltung.data.gastgeberverein.veranstaltung)
                    .get()
                    .then((gastgeberEvent) => {
                      var event = gastgeberEvent.data()
                      daten.veranstaltung = event
                      daten.veranstaltung.id = gastgeberEvent.id
                      daten.sportstaette = {
                        name: event.sportstaette,
                      }
                      firebase
                        .firestore()
                        .collection('User')
                        .doc(this.veranstaltung.data.gastgeberverein.id)
                        .collection('Anwesenheit')
                        .where('veranstaltung.id', '==', gastgeberEvent.id)
                        .where('verein.trainer', '==', this.coaches[index].id)
                        .where(
                          'verein.team',
                          '==',
                          this.veranstaltung.data.mannschaft,
                        )
                        .where('verein.id', '==', this.user.data.uid)
                        .get()
                        .then((docRef) => {
                          var exists = false
                          docRef.forEach((doc) => {
                            if (doc.exists) {
                              exists = true
                            }
                          })
                          if (!exists) {
                            firebase
                              .firestore()
                              .collection('User')
                              .doc(this.veranstaltung.data.gastgeberverein.id)
                              .collection('Anwesenheit')
                              .add(daten)
                              .then(() => {
                                this.aktualisiereListe()
                                this.loading = false
                                this.saved = true
                              })
                          } else {
                            this.aktualisiereListe()
                            this.loading = false
                            this.saved = true
                          }
                        })
                    })
                } else {
                  this.aktualisiereListe()
                  this.loading = false
                  this.saved = true
                }
              })
          } else {
            console.log('exists')
            this.loading = ''
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    abwesendCoach(index) {
      this.loading = this.coaches[index].id
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.trainer', '==', this.coaches[index].id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            doc.ref.delete().then(() => {
              if (this.veranstaltung.data.gastgeberverein) {
                firebase
                  .firestore()
                  .collection('User')
                  .doc(this.veranstaltung.data.gastgeberverein.id)
                  .collection('Anwesenheit')
                  .where(
                    'veranstaltung.id',
                    '==',
                    this.veranstaltung.data.gastgeberverein.veranstaltung,
                  )
                  .where('verein.trainer', '==', this.coaches[index].id)
                  .where(
                    'verein.team',
                    '==',
                    this.veranstaltung.data.mannschaft,
                  )
                  .where('verein.id', '==', this.user.data.uid)
                  .get()
                  .then((gastgeberRef) => {
                    gastgeberRef.forEach((gastgeberDoc) => {
                      gastgeberDoc.ref.delete().then(() => {
                        this.aktualisiereListe()
                        this.loading = false
                        this.saved = true
                      })
                    })
                  })
              } else {
                this.aktualisiereListe()
                this.loading = false
                this.saved = true
              }
            })
          })
        })
    },
    abwesend(index) {
      this.loading = this.member[index].id
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.spieler', '==', this.member[index].id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            doc.ref.delete().then(() => {
              if (this.veranstaltung.data.gastgeberverein) {
                firebase
                  .firestore()
                  .collection('User')
                  .doc(this.veranstaltung.data.gastgeberverein.id)
                  .collection('Anwesenheit')
                  .where(
                    'veranstaltung.id',
                    '==',
                    this.veranstaltung.data.gastgeberverein.veranstaltung,
                  )
                  .where('verein.spieler', '==', this.member[index].id)
                  .where(
                    'verein.team',
                    '==',
                    this.veranstaltung.data.mannschaft,
                  )
                  .where('verein.id', '==', this.user.data.uid)
                  .get()
                  .then((gastgeberRef) => {
                    gastgeberRef.forEach((gastgeberDoc) => {
                      gastgeberDoc.ref.delete().then(() => {
                        this.aktualisiereListe()
                        this.loading = false
                        this.saved = true
                      })
                    })
                  })
              } else {
                this.aktualisiereListe()
                this.loading = false
                this.saved = true
              }
            })
          })
        })
    },
    getestet(index) {
      this.loading = this.member[index].id
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.spieler', '==', this.member[index].id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            doc.ref
              .update({
                ggg: {
                  geimpft: this.member[index].geimpft ? true : false,
                  genesen: this.member[index].genesen ? true : false,
                  getestet: true,
                },
              })
              .then(() => {
                if (this.veranstaltung.data.gastgeberverein) {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.veranstaltung.data.gastgeberverein.id)
                    .collection('Anwesenheit')
                    .where(
                      'veranstaltung.id',
                      '==',
                      this.veranstaltung.data.gastgeberverein.veranstaltung,
                    )
                    .where('verein.spieler', '==', this.member[index].id)
                    .where(
                      'verein.team',
                      '==',
                      this.veranstaltung.data.mannschaft,
                    )
                    .where('verein.id', '==', this.user.data.uid)
                    .get()
                    .then((gastgeberRef) => {
                      gastgeberRef.forEach((gastgeberDoc) => {
                        gastgeberDoc.ref
                          .update({
                            ggg: {
                              geimpft: this.member[index].geimpft
                                ? true
                                : false,
                              genesen: this.member[index].genesen
                                ? true
                                : false,
                              getestet: true,
                            },
                          })
                          .then(() => {
                            this.aktualisiereListe()
                            this.loading = false
                            this.saved = true
                          })
                      })
                    })
                } else {
                  this.aktualisiereListe()
                  this.loading = false
                  this.saved = true
                }
              })
          })
        })
    },
    nicht_getestet(index) {
      this.loading = this.member[index].id
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.spieler', '==', this.member[index].id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            doc.ref
              .update({
                ggg: {
                  geimpft: this.member[index].geimpft ? true : false,
                  genesen: this.member[index].genesen ? true : false,
                  getestet: true,
                },
              })
              .then(() => {
                if (this.veranstaltung.data.gastgeberverein) {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.veranstaltung.data.gastgeberverein.id)
                    .collection('Anwesenheit')
                    .where(
                      'veranstaltung.id',
                      '==',
                      this.veranstaltung.data.gastgeberverein.veranstaltung,
                    )
                    .where('verein.spieler', '==', this.member[index].id)
                    .where(
                      'verein.team',
                      '==',
                      this.veranstaltung.data.mannschaft,
                    )
                    .where('verein.id', '==', this.user.data.uid)
                    .get()
                    .then((gastgeberRef) => {
                      gastgeberRef.forEach((gastgeberDoc) => {
                        gastgeberDoc.ref
                          .update({
                            ggg: {
                              geimpft: this.member[index].geimpft
                                ? true
                                : false,
                              genesen: this.member[index].genesen
                                ? true
                                : false,
                              getestet: true,
                            },
                          })
                          .then(() => {
                            this.aktualisiereListe()
                            this.loading = false
                            this.saved = true
                          })
                      })
                    })
                } else {
                  this.aktualisiereListe()
                  this.loading = false
                  this.saved = true
                }
              })
          })
        })
    },
    getestetCoach(index) {
      this.loading = this.coaches[index].id
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.trainer', '==', this.coaches[index].id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            doc.ref
              .update({
                ggg: {
                  geimpft: this.member[index].geimpft ? true : false,
                  genesen: this.member[index].genesen ? true : false,
                  getestet: true,
                },
              })
              .then(() => {
                if (this.veranstaltung.data.gastgeberverein) {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.veranstaltung.data.gastgeberverein.id)
                    .collection('Anwesenheit')
                    .where(
                      'veranstaltung.id',
                      '==',
                      this.veranstaltung.data.gastgeberverein.veranstaltung,
                    )
                    .where('verein.trainer', '==', this.coaches[index].id)
                    .where(
                      'verein.team',
                      '==',
                      this.veranstaltung.data.mannschaft,
                    )
                    .where('verein.id', '==', this.user.data.uid)
                    .get()
                    .then((gastgeberRef) => {
                      gastgeberRef.forEach((gastgeberDoc) => {
                        gastgeberDoc.ref
                          .update({
                            ggg: {
                              geimpft: this.member[index].geimpft
                                ? true
                                : false,
                              genesen: this.member[index].genesen
                                ? true
                                : false,
                              getestet: true,
                            },
                          })
                          .then(() => {
                            this.aktualisiereListe()
                            this.loading = false
                            this.saved = true
                          })
                      })
                    })
                } else {
                  this.aktualisiereListe()
                  this.loading = false
                  this.saved = true
                }
              })
          })
        })
    },
    nicht_getestetCoach(index) {
      this.loading = this.coaches[index].id
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .where('verein.trainer', '==', this.coaches[index].id)
        .where('verein.team', '==', this.veranstaltung.data.mannschaft)
        .where('verein.id', '==', this.user.data.uid)
        .get()
        .then((docRef) => {
          docRef.forEach((doc) => {
            doc.ref
              .update({
                ggg: {
                  geimpft: this.member[index].geimpft ? true : false,
                  genesen: this.member[index].genesen ? true : false,
                  getestet: true,
                },
              })
              .then(() => {
                if (this.veranstaltung.data.gastgeberverein) {
                  firebase
                    .firestore()
                    .collection('User')
                    .doc(this.veranstaltung.data.gastgeberverein.id)
                    .collection('Anwesenheit')
                    .where(
                      'veranstaltung.id',
                      '==',
                      this.veranstaltung.data.gastgeberverein.veranstaltung,
                    )
                    .where('verein.trainer', '==', this.coaches[index].id)
                    .where(
                      'verein.team',
                      '==',
                      this.veranstaltung.data.mannschaft,
                    )
                    .where('verein.id', '==', this.user.data.uid)
                    .get()
                    .then((gastgeberRef) => {
                      gastgeberRef.forEach((gastgeberDoc) => {
                        gastgeberDoc.ref
                          .update({
                            ggg: {
                              geimpft: this.member[index].geimpft
                                ? true
                                : false,
                              genesen: this.member[index].genesen
                                ? true
                                : false,
                              getestet: true,
                            },
                          })
                          .then(() => {
                            this.aktualisiereListe()
                            this.loading = false
                            this.saved = true
                          })
                      })
                    })
                } else {
                  this.aktualisiereListe()
                  this.loading = false
                  this.saved = true
                }
              })
          })
        })
    },
    deleteGast(gast) {
      this.deleting = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(gast.id)
        .delete()
        .then(() => {
          if (this.veranstaltung.data.gastgeberverein) {
            firebase
              .firestore()
              .collection('User')
              .doc(this.veranstaltung.data.gastgeberverein.id)
              .collection('Anwesenheit')
              .doc(gast.verein.gastspieler)
              .delete()
              .then(() => {
                this.aktualisiereListe()
                this.deleting = false
                this.delguest = false
                this.del_guest = ''
              })
          } else {
            this.aktualisiereListe()
            this.deleting = false
            this.delguest = false
            this.del_guest = ''
          }
        })
    },
    deleteGastCoach(gast) {
      this.deleting = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(gast.id)
        .delete()
        .then(() => {
          if (this.veranstaltung.data.gastgeberverein) {
            firebase
              .firestore()
              .collection('User')
              .doc(this.veranstaltung.data.gastgeberverein.id)
              .collection('Anwesenheit')
              .doc(gast.verein.gasttrainer)
              .delete()
              .then(() => {
                this.aktualisiereListe()
                this.deleting = false
                this.delcoach = false
                this.del_coach = ''
              })
          } else {
            this.aktualisiereListe()
            this.deleting = false
            this.delcoach = false
            this.del_coach = ''
          }
        })
    },
    aktualisiereListe() {
      var i
      this.anwesende_spieler = []
      this.ggg_spieler = []
      this.anwesende_coaches = []
      this.ggg_coaches = []
      this.anwesende_gastspieler = []
      this.anwesende_gasttrainer = []
      var liste = this.veranstaltung.anwesenheit
      for (i = 0; i < liste.length; i++) {
        if (liste[i].verein) {
          if (
            liste[i].verein.id == this.user.data.uid &&
            liste[i].verein.team == this.veranstaltung.data.mannschaft &&
            liste[i].verein.spieler
          ) {
            this.anwesende_spieler.push(liste[i].verein.spieler)
            if (liste[i].ggg) {
              if (liste[i].ggg.getestet) {
                this.ggg_spieler.push(liste[i].verein.spieler)
              }
            }
          } else if (
            liste[i].verein.id == this.user.data.uid &&
            liste[i].verein.team == this.veranstaltung.data.mannschaft &&
            liste[i].verein.gastspieler
          ) {
            this.anwesende_gastspieler.push(liste[i])
          } else if (
            liste[i].verein.id == this.user.data.uid &&
            liste[i].verein.team == this.veranstaltung.data.mannschaft &&
            liste[i].verein.gasttrainer
          ) {
            this.anwesende_gasttrainer.push(liste[i])
          } else if (
            liste[i].verein.id == this.user.data.uid &&
            liste[i].verein.team == this.veranstaltung.data.mannschaft &&
            liste[i].verein.trainer
          ) {
            this.anwesende_coaches.push(liste[i].verein.trainer)
            if (liste[i].ggg) {
              if (liste[i].ggg.getestet) {
                this.ggg_coaches.push(liste[i].verein.trainer)
              }
            }
          }
        }
      }
      var member_old = this.member
      this.member = []
      for (i = 0; i < member_old.length; i++) {
        if (this.anwesende_spieler.includes(member_old[i].id)) {
          this.member.push(member_old[i])
          this.member[i].anwesend = true

          if (this.ggg_spieler.includes(member_old[i].id)) {
            this.member[i].ggg = true
            this.member[i].getestet = true
          } else {
            this.member[i].ggg = false
          }
        } else {
          this.member.push(member_old[i])
          this.member[i].anwesend = false
          this.member[i].ggg = false
        }
      }
      var coaches_old = this.coaches
      this.coaches = []
      for (i = 0; i < coaches_old.length; i++) {
        if (this.anwesende_coaches.includes(coaches_old[i].id)) {
          this.coaches.push(coaches_old[i])
          this.coaches[i].anwesend = true
          if (this.ggg_coaches.includes(coaches_old[i].id)) {
            this.coaches[i].ggg = true
          } else {
            this.coaches[i].ggg = false
          }
        } else {
          this.coaches.push(coaches_old[i])
          this.coaches[i].anwesend = false
          this.coaches[i].ggg = false
        }
      }
    },
  },
}
</script>
